
<template>
  <van-pull-refresh
    v-model="isLoad"
    style="min-height:100vh"
    @refresh="onRefresh"
  >
    <van-list
      v-model="load"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div
        v-for="(item,index) in list"
        :key="index"
        style="background: #ffffff; border-radius: 10px;margin-bottom:10px"
      >
        <div
          class="cell-class"
          style="border-bottom:1px solid #EEEEEE"
        >
          <div style="display: inline-block">
            兑换时间：{{ item.createtime }}
          </div>
          <div style="float: right">{{item.orderStatusText}}</div>
        </div>
        <div
          v-for="child in item.goodsList"
          :key='child.id'
        >
          <van-card
            style="background: #ffffff;"
            :num="child.number"
            :title="child.goodsName"
            :thumb="child.picUrl"
          >
            <template #desc>
              <div style="padding:10px 0">兑换号码：{{item.orderMobile}}</div>
            </template>
            <template #price>
              <span style="color:#8C8C8C">{{ item.actualPrice }}积分</span>
            </template>
          </van-card>
        </div>
        <div style="text-align:right;padding:0 10px 12px 0">

          <van-button
            v-if="isActive===1"
            color="#262626"
            round
            size="small"
            plain
            style="margin-right:5px"
            @click="payClick(item)"
          >继续支付</van-button>
          <!-- <van-button
            v-if="isActive>1"
            color="#262626"
            round
            size="small"
            plain
            style="margin-right:5px"
            @click="onCustomer"
          >联系客服</van-button> -->
          <van-button
            v-if="isActive===1 || isActive===2 "
            color="#262626"
            round
            size="small"
            plain
            style="margin-right:5px"
            @click="getAddress(item)"
          >修改地址</van-button>
          <!-- <van-button
            v-if="isActive===3"
            color="#262626"
            round
            size="small"
            plain
            style="margin-right:5px"
            @click="getLogistics(item)"
          >物流信息</van-button> -->

          <van-button
            v-if="isActive>1"
            color="#262626"
            round
            size="small"
            plain
            style="margin-right:5px"
            @click="getOrderInfo(item.id)"
          >查看详情</van-button>
          <van-button
            v-if="isActive===4"
            color="#262626"
            round
            size="small"
            plain
            style="margin-right:5px"
            @click="onAgain(item)"
          >再来一单</van-button>
        </div>

      </div>

    </van-list>
  </van-pull-refresh>
</template>

<script>
export default {
  props: {
    active: {
      type: String,
      default: "1",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    finished: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      load: false,
      isLoad: false,
    };
  },
  watch: {
    loading(val) {
      this.load = val;
    },
    isLoading(val) {
      this.isLoad = val;
    },
  },
  computed: {
    isActive() {
      return Number(this.active);
    },
  },
  created() {
    this.load = this.loading;
    this.isLoad = this.isLoading;
  },
  methods: {
    onRefresh() {
      this.$emit("onRefresh");
    },
    onLoad() {
      this.$emit("onLoad");
    },
    onCustomer() {
      // 联系客服
      this.$emit("onCustomer");
    },
    getAddress(item) {
      //修改地址
      this.$emit("getAddress", item);
    },
    getLogistics(item) {
      //物流信息
      this.$emit("getLogistics", item);
    },
    getOrderInfo(item) {
      //查看详情
      this.$emit("getOrderInfo", item);
    },
    onAgain(item) {
      //重新兑换
      this.$emit("onAgain", item);
    },
    payClick(item) {
      this.$emit("payClick", item);
    },
  },
};
</script>

<style lang='scss' scoped>
</style>
