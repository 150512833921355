<template>
  <div
    class="order"
    :class="{'order_tab':seachShow}"
  >
    <header-back
      v-if="seachShow"
      :seachShow='seachShow'
      @onSearch='onSearch'
    ></header-back>
    <van-tabs
      v-model="active"
      line-width="13"
      :animated="true"
      :title-active-color="variables.textColor"
      style="min-height:100%"
      class="order-tabs"
      @click="onClick"
    >
      <van-tab
        name='0'
        title="全部"
        style="padding: 10px;"
      >
        <list-view
          :isLoading='isLoading'
          :loading='loading'
          :finished='finished'
          :list='list'
          :active='active'
          @onLoad='onLoad'
          @onRefresh='onRefresh'
          @onCustomer='onCustomer'
          @getLogistics='getLogistics'
          @getOrderInfo='getOrderInfo'
          @onAgain='onAgain'
          @payClick='payClick'
          @getAddress='getAddress'
        ></list-view>
      </van-tab>
      <van-tab
        name='1'
        title="待付款"
        style="padding: 10px;"
      >
        <list-view
          :isLoading='isLoading'
          :loading='loading'
          :finished='finished'
          :list='list'
          :active='active'
          @onLoad='onLoad'
          @onRefresh='onRefresh'
          @onCustomer='onCustomer'
          @getLogistics='getLogistics'
          @getOrderInfo='getOrderInfo'
          @onAgain='onAgain'
          @payClick='payClick'
          @getAddress='getAddress'
        ></list-view>

      </van-tab>
      <van-tab
        name='2'
        title="待发货"
        style="padding: 10px"
      >
        <list-view
          :isLoading='isLoading'
          :loading='loading'
          :finished='finished'
          :list='list'
          :active='active'
          @onLoad='onLoad'
          @onRefresh='onRefresh'
          @onCustomer='onCustomer'
          @getLogistics='getLogistics'
          @getOrderInfo='getOrderInfo'
          @onAgain='onAgain'
          @getAddress='getAddress'
        ></list-view>
      </van-tab>
      <van-tab
        name='3'
        title="待收货"
        style="padding: 10px"
      >
        <list-view
          :isLoading='isLoading'
          :loading='loading'
          :finished='finished'
          :list='list'
          :active='active'
          @onLoad='onLoad'
          @onRefresh='onRefresh'
          @onCustomer='onCustomer'
          @getLogistics='getLogistics'
          @getOrderInfo='getOrderInfo'
          @onAgain='onAgain'
        ></list-view>

      </van-tab>
      <van-tab
        name='4'
        title="已完成"
        style="padding: 10px"
      >
        <list-view
          :isLoading='isLoading'
          :loading='loading'
          :finished='finished'
          :list='list'
          :active='active'
          @onLoad='onLoad'
          @onRefresh='onRefresh'
          @onCustomer='onCustomer'
          @getLogistics='getLogistics'
          @getOrderInfo='getOrderInfo'
          @onAgain='onAgain'
        ></list-view>
      </van-tab>

    </van-tabs>
    <!-- 物流信息 -->
    <van-popup
      v-model="show"
      closeable
      round
      close-icon="close"
      :style="{ padding: '10px 0px', width: '80%'}"
    >
      <div style="padding:10px 20px;">
        <div style="text-align:center;font-size:18px">物流信息</div>
        <br>
        <van-row style="margin-top:20px;font-size:14px">
          <van-col
            span="8"
            style="color:#8A8A8A;font-size:14px"
          >物流公司</van-col>
          <van-col span="8">{{ logistics.name }}</van-col>
        </van-row>
        <van-row style="margin-top:20px">
          <van-col
            span="8"
            style="color:#8A8A8A;font-size:14px"
          >快递单号</van-col>
          <van-col span="8">{{ logistics.orderNo }}</van-col>
        </van-row>
        <br>
        <br>
        <div style="text-align:center">
          <van-button
            :color="variables.textColor"
            plain
            size="small"
            @click="copy(logistics.orderNo ,$event)"
          >复制单号</van-button>
        </div>
      </div>
    </van-popup>
    <!-- 联系客服 -->
    <ser-view :serShow.sync='serShow'></ser-view>
    <!-- 订单详情 -->
    <van-popup
      v-model="orderShow"
      closeable
      round
      close-icon="close"
      :style="{ padding: '10px 0px', width: '88%'}"
    >
      <div style="width:100%;padding:10px 20px;box-sizing: border-box">
        <div style="text-align:center;font-size:18px">订单详情</div>
        <div
          v-for="item in orderData.orderGoods"
          :key='item.id'
        >
          <div style="text-align:center;margin-top:20px;">
            <van-image
              width="120"
              height="120"
              :src="item.picUrl"
            />
          </div>
          <div
            @click="infoClick(item)"
            style="display:flex;justify-content:space-between;align-items: center;padding-top:5px;font-size:14px;font-weight: 500;"
          >
            <div>{{item.goodsName}}*{{item.number}}</div>
            <div>
              <van-icon name="arrow" />
            </div>
          </div>
        </div>
        <div style="line-height:30px;margin-top:20px;font-size:12px">
          <div style="display:flex;justify-content:space-between;border-top: thin solid #efefef;padding-top:5px">
            <div>消费总额</div>
            <div>{{ orderInfo.orderPrice }}积分</div>
          </div>
          <div style="display:flex;justify-content:space-between;color:red">
            <div>实际支付</div>
            <div>{{ orderInfo.actualPrice }}积分</div>
          </div>
        </div>

        <div style="line-height:30px;color:#8A8A8A;font-size:12px">
          <div style="display:flex;justify-content:space-between;align-items: baseline;border-top: thin solid #efefef;padding-top:5px">
            <div style="width:100px">收货地址</div>
            <div style="line-height:20px">{{ orderInfo.address }}</div>
          </div>
          <div style="display:flex;justify-content:space-between;">
            <div style="width:100px">兑换时间</div>
            <div>{{ orderInfo.payTime }}</div>
          </div>
          <div style="display:flex;justify-content:space-between;">
            <div style="width:100px">订单编号</div>
            <div>{{ orderInfo.orderSn }}</div>
          </div>
        </div>

        <!-- <div style="text-align:center;margin-top:20px;">
          <van-button
            round
            type="info"
            native-type="submit"
            color="linear-gradient(to right, #F67743, #F75D22)"
            style="padding:0 50px"
            @click="serShow = true"
          >联系客服</van-button>
        </div> -->

      </div>
    </van-popup>
    <!-- //立即兑换 -->
    <points-pop
      :integralPopup.sync='integralPopup'
      :retailPrice='retailPrice'
      :userShow='userShow'
      :orderSnId='orderSnId'
      :addressData='addressData'
      @onExSubmit='onExSubmit'
    ></points-pop>
    <!-- 修改地址 -->
    <update-addess
      :addressShow.sync='addressShow'
      :addressData='addressData'
      @onAddressSub='onAddressSub'
    ></update-addess>
  </div>
</template>

<script>
import clipboard from "@/utils/clipboard";
import API from "../../api/http";
import variables from "@/styles/variables.scss";
import ListView from "./components/listVIew.vue";
import SerView from "@/components/ser-view";
import areaList from "@/utils/area";
import PointsPop from "@/components/points-pop";
import HeaderBack from "../../components/header-back";
import UpdateAddess from "./components/updateAddess.vue";
import { Toast } from "vant";
export default {
  name: "Sorder",
  components: {
    ListView,
    SerView,
    PointsPop,
    HeaderBack,
    UpdateAddess,
  },
  data() {
    return {
      areaList,
      active: "1",
      list: [],
      userShow: false,
      listQuery: {
        page: 1,
        showType: 0,
      },
      loading: false,
      finished: false,
      isLoading: false,
      show: false,
      serShow: false,
      orderShow: false,
      logistics: {
        name: "",
        orderNo: "",
      },
      orderInfo: {
        name: "扶贫 好柿薯于你干木耳（黑）100g",
        icon: "https://jf-asset2.10086.cn/pic/ware/202109/614449bea1a9ff755cea433c.jpg",
        addrid: 0,
        amount: 0,
        channel: "",
        createtime: "",
        ctime: "",
        discount: 0,
        fv: 0,
        id: 0,
        kdid: "",
        kdname: "",
        mobile: "",
        num: 0,
        orderid: "",
        pcode: "",
        pid: 0,
        points: 0,
        region: "",
        source: "",
        status: 0,
        subid: "",
        uid: 0,
        upper: 0,
        ydid: "",
      },
      orderData: "",
      integralPopup: false,
      retailPrice: 0,
      seachShow: true,
      addressShow: false,
      addressData: "",
      orderAddress: "",
      orderSnId: "",
    };
  },
  computed: {
    variables() {
      return variables;
    },
    userType() {
      return this.$store.getters["user/userType"];
    },
  },
  created() {
    this.active = this.$route.query.active;
    if (this.active) {
      this.listQuery.showType = Number(this.active);
    }
    if (this.userType === 0) {
      this.seachShow = false;
    }
  },
  methods: {
    onLoad() {
      if (this.isLoading) {
        this.list = [];
        this.isLoading = false;
      }

      this.loading = true;
      this.getList();
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.isLoading = true;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.listQuery.page = 1;
      this.list = [];
      this.getList(1);
    },
    getList(val) {
      API.getOrderList(this.listQuery)
        .then((res) => {
          let data = res.data;
          this.loading = false;

          this.seachNum = data.total;

          if (data.list.length > 0) {
            let list = data.list;
            if (data.pages === 1) {
              this.finished = true;
            }

            if (val) {
              this.isLoading = false;

              this.list = list;
            } else {
              this.list = this.list.concat(list);
            }

            this.listQuery.page++;
          } else {
            this.isLoading = false;
            this.finished = true;
          }
        })
        .catch(() => {
          this.isLoading = false;
          this.loading = false;
          this.finished = true;
        });
    },
    onSearch(val) {
      //搜搜
      if (val.trim()) {
        this.listQuery.mobile = val;
      } else {
        this.$delete(this.listQuery, "mobile");
      }
      this.onRefresh();
    },
    getOrderInfo(id) {
      // 查看详情
      const obj = {
        orderId: id,
      };
      API.getOrderDetail(obj).then((res) => {
        this.orderData = res.data;
        this.orderInfo = res.data.orderInfo;
        this.orderShow = true;
      });
    },
    getLogistics(row) {
      //查看物流
      this.logistics = {
        name: row.kdname,
        orderNo: row.kdid,
      };
      this.show = true;
    },
    infoClick(item) {
      this.$router.push({
        path: "/commodity",
        query: {
          id: item.goodsId,
        },
      });
    },
    onAgain(item) {
      //重新兑换
      this.$router.push({
        path: "/commodity",
        query: {
          id: item.id,
        },
      });
    },
    payClick(item) {
      console.log("kk", item);
      //  继续支付\
      const obj = {
        orderId: item.id,
      };
      API.getOrderDetail(obj).then((res) => {
        let orderInfo = res.data.orderInfo;
        let addressData = {
          orderId: orderInfo.id,
          username: orderInfo.consignee,
          usertel: orderInfo.mobile,
          address: orderInfo.address,
        };
        this.addressData = addressData;
        this.retailPrice = item.actualPrice;
        this.orderSnId = item.id;
        this.integralPopup = true;
      });
    },
    getAddress(item) {
      //修改地址
      const obj = {
        orderId: item.id,
      };
      API.getOrderDetail(obj).then((res) => {
        let orderInfo = res.data.orderInfo;
        let addressData = {
          orderId: orderInfo.id,
          username: orderInfo.consignee,
          usertel: orderInfo.mobile,
          address: orderInfo.address,
        };
        this.addressData = addressData;
        this.addressShow = true;
        // this.orderShow = true;
      });
    },
    onAddressSub(data) {
      //确定修改地址
      console.log(data);

      API.postUpdateOrderAds(data).then((res) => {
        Toast("修改地址成功");
        this.addressShow = false;
      });
    },
    onClick(name, title) {
      //tab点击事件
      const a = name;

      this.listQuery.showType = a;
      this.onRefresh();
    },

    copy(text, event) {
      //复制
      clipboard(text, event);
    },
    onCustomer() {
      //联系客服
      this.serShow = true;
    },

    onExSubmit() {
      //提交兑换

      setTimeout(() => {
        this.integralPopup = false;
        this.onRefresh();
      }, 1000);
    },
    onConfirm(values) {
      this.uservalue = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join("/");
      this.showArea = false;
    },
  },
};
</script>
<style lang="scss">
@import "../../styles/variables.scss";
.order {
  background-color: $primary;
  .cell-class {
    color: #999393;
    font-size: 12px;
    height: 40px;
    line-height: 38px;
    padding: 0 15px;
  }
  .van-tabs__line {
    background-color: $textColor;
  }
}
.order_tab {
  .order-tabs > .van-tabs__wrap {
    position: fixed;
    top: 44px;
    width: 100%;
    z-index: 11;
  }
  .order-tabs > .van-tabs__content {
    margin-top: 100px;
  }
}
</style>
