<template>

  <van-popup
    v-model="show"
    closeable
    round
    @close="close"
    position="bottom"
    :style="{ height: '90%' }"
    style="background-color:#fff;padding-bottom:20px;padding-top:0px"
  >
    <div
      class="points-address"
      style="background-color:#fff;"
      ref="datePopper"
    >
      <div class="points-title">
        修改地址

      </div>
      <van-form>
        <van-field
          v-model="username"
          v-show="stickyShow "
          readonly
          clearable
          name="姓名"
          label="当前姓名"
          placeholder="收货人姓名"
        />
        <van-field
          v-model="usertel"
          v-show="stickyShow "
          readonly
          clearable
          name="当前电话"
          label="当前电话"
          placeholder="收货人电话"
        />
        <van-field
          v-model="address"
          v-show="stickyShow "
          readonly
          rows="1"
          type="textarea"
          clearable
          name="当前地址"
          label="当前地址"
          placeholder="当前地址"
        />

        <van-field
          v-model="nickname"
          clearable
          name="姓名"
          label="姓名"
          placeholder="收货人姓名"
        />
        <van-field
          v-model="mobile"
          clearable
          name="电话"
          label="电话"
          type="digit"
          maxlength='11'
          placeholder="收货人电话"
        />
        <van-field
          readonly
          clickable
          name="area"
          :value="uservalue"
          label="地区"
          placeholder="省市区"
          @click="showArea = true"
        />
        <van-popup
          v-model="showArea"
          round
          position="bottom"
        >
          <van-cascader
            title="请选择所在地区"
            :options="options"
            @close="showArea = false"
            @finish="onFinish"
          />
        </van-popup>
        <!-- <van-popup
          v-model="showArea"
          position="bottom"
        >
          <van-area
            :area-list="areaList"
            @confirm="onConfirm"
            @cancel="showArea = false"
          />
        </van-popup> -->

        <van-cell title="">
          <!-- 使用 right-icon 插槽来自定义右侧图标 -->
          <template #title>
            <span class="custom-title">详细</span>

          </template>
          <template #right-icon>
            <van-popover
              v-model="showPopover"
              :actions="searchResult"
              @select="onSelect"
              style="width:70% "
            >
              <template #reference>
                <van-field
                  v-model="userdetail"
                  label=""
                  rows="2"
                  autosize
                  clearable
                  type="textarea"
                  placeholder="街道门牌/楼层房间号等信息"
                  style="padding:0"
                  id='textarea'
                  @input='onDetailInput'
                  @focus='onFocus()'
                  @blur='onBlur()'
                />
              </template>
            </van-popover>
          </template>
        </van-cell>

        <!-- <van-popover
          v-model="showPopover"
          :actions="searchResult"
          @select="onSelect"
          style="width:100% "
        >
          <template #reference>
            <van-field
              v-model="userdetail"
              rows="1"
              autosize
              clearable
              label="详细"
              type="textarea"
              placeholder="街道门牌/楼层房间号等信息"
              @input='onDetailInput'
            />
          </template>
        </van-popover> -->

      </van-form>
    </div>
    <div
      v-show="showBtn"
      class="points-address-btn"
      style="position: absolute; bottom: 0;left:0; width: 100%;text-align: center;"
    >
      <van-button
        color="#ccc"
        plain
        @click="close"
        round
        style="margin-bottom:20px;padding-left:30px;padding-right:30px"
      >
        取消
      </van-button>
      <van-button
        block
        color="linear-gradient(180deg, #F67743 0%, #F75D22 100%)"
        @click="onAddressSub"
      >
        确认
      </van-button>
      <div id="allmap"></div>
    </div>
  </van-popup>
</template>

<script>
import areaList from "@/utils/area.js";
import { Toast } from "vant";
import validateRule from "@/utils/validateRule";
import area from "@/utils/pcas-code.json";
import API from "@/api/http";
//立即兑换组件
export default {
  props: {
    addressShow: {
      type: Boolean,
      default: false,
    },

    addressData: {
      default: "",
    },
  },
  data() {
    return {
      show: false,
      areaList,
      address: "",
      username: "",
      usertel: "",
      uservalue: "",
      userdetail: "",
      sendPopup: false,
      showArea: false,
      phoneCode: "",
      timer: "",
      areaCode: "",
      integralCount: "发送验证码",
      city: "", //市
      county: "", //区
      province: "", //省
      showPopover: false,
      searchResult: [],
      timerOut: null,
      options: [],
      nickname: "",
      mobile: "",
      showBtn: true,
      clientHeight:
        document.documentElement.clientHeight || document.body.clientHeight,
      stickyShow: true,
    };
  },
  watch: {
    addressShow(val) {
      this.show = val;
    },
    addressData(val) {
      console.log(val);
      this.setValue();
    },
    showArea(val) {
      this.showBtn = !val;
    },
  },
  created() {
    this.show = this.addressShow;
    this.getProvince2();
    this.setValue();
  },
  mounted() {
    document.addEventListener("mouseup", this.setHeadPosition);
    //中解决定位在底部的按钮，键盘弹出将input框遮住的问题
    window.onresize = () => {
      var resizeHeight =
        document.documentElement.clientHeight || document.body.clientHeight;

      if (this.clientHeight > resizeHeight) {
        this.showBtn = false;
      } else {
        this.showBtn = true;
        this.stickyShow = true;
      }
    };
  },
  beforeDestroy() {
    document.removeEventListener("mouseup", this.setHeadPosition);
    // window.removeEventListener('mouseup', e => {}, true)
  },
  methods: {
    setHeadPosition(e) {
      const that = this;
      console.log("updateAddess", e.target.id);
      // 点击其他地方关闭 popover气泡
      if (that.$refs.datePopper) {
        if (e.target.id !== "textarea") {
          that.showPopover = false;
        }
      }

      // if (!that.$refs.datePopper.contains(e.target)) {
      // }
    },
    getProvince2() {
      let list = area;
      this.options = list.map((item) => {
        return {
          text: item.name,
          value: item.code,
          children: item.children.map((c) => {
            let clIst = [
              ...c.children,
              {
                name: "其他",
                code: c.code + 99,
                children: [],
              },
            ];
            return {
              text: c.name,
              value: c.code,
              children: clIst.map((m) => {
                return {
                  text: m.name,
                  value: m.code,
                  children: m.children
                    .map((t) => {
                      return {
                        text: t.name,
                        value: t.code,
                      };
                    })
                    .concat([
                      {
                        text: "其他",
                        value:
                          m.code +
                          (m.children.length + 1 > 10
                            ? m.children.length + 1
                            : 0 + m.children.length + 1),
                      },
                    ]),
                };
              }),
            };
          }),
        };
      });
    },
    setValue() {
      this.username = this.addressData.username;
      this.usertel = this.addressData.usertel;
      this.address = this.addressData.address;
      this.uservalue = "";
      this.userdetail = "";
      this.nickname = this.addressData.username;
      this.mobile = this.addressData.usertel;
    },
    close() {
      this.$emit("update:addressShow", false);
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    onFocus() {
      // var resizeHeight =
      //   document.documentElement.clientHeight || document.body.clientHeight;
      // console.log("stickyShow", this.clientHeight, resizeHeight);
      if (this._isMobile()) {
        //   console.log("stickyShow");
        this.stickyShow = false;
      }
    },
    onBlur() {
      this.stickyShow = true;
    },
    onDetailInput(val) {
      if (val.trim() && this.province) {
        if (this.timerOut) {
          clearTimeout(this.timerOut);
          this.timerOut = null;
        }
        this.timerOut = setTimeout(() => {
          this.getAddress(val);
        }, 1000);
      } else {
        clearTimeout(this.timerOut);
        this.timerOut = null;
        this.searchResult = [];
      }
    },
    getAddress(val) {
      API.setAddressQuery({
        city: this.city,
        keywords: val,
        userId: this.userId,
      }).then((res) => {
        console.log(res);
        this.showPopover = true;
        this.searchResult = res.data.list.map((item) => {
          return {
            text: item,
            icon: "location-o",
            className: "action-dom",
          };
        });
      });
      // var myGeo = new BMap.Geocoder();
      // let that = this;

      // myGeo.getPoint(
      //   val,
      //   function (point) {
      //     if (point) {
      //       console.log("ll", point);
      //       let map = new BMap.Map("allmap");
      //       map.centerAndZoom(new BMap.Point(point.lng, point.lat), 11);
      //       that.getSeach(map, val);
      //     }
      //   },
      //   this.province
      // );
    },
    getSeach(map, val) {
      // let map = new BMap.Map("allmap");
      // map.centerAndZoom(new BMap.Point(116.331398, 39.897445), 11);
      // //创建地址解析器实例
      // // 将地址解析结果显示在地图上，并调整地图视野
      let that = this;
      var options = {
        renderOptions: { map: map },
        onSearchComplete: function (results) {
          console.log(1122);
          if (local.getStatus() === BMAP_STATUS_SUCCESS) {
            that.searchResult = [];
            that.showPopover = true;
            for (var i = 0; i <= results.getCurrentNumPois(); i++) {
              let m = results.getPoi(i);
              if (m) {
                let index = m.address.indexOf(that.county) || 0;
                let len = that.county.length;
                that.searchResult.push({
                  text: m.address.substring(index + len) + " " + m.title || "",
                  icon: "location-o",
                  className: "action-dom",
                });
              }
            }
            console.log("kkk", that.searchResult);
          } else {
            Toast("您输入的地址没有解析到结果！");
          }
        },
      };
      var local = new BMap.LocalSearch(map, options);
      let name = this.city + this.county + val;
      local.search(name);
    },
    onSelect(val) {
      //选中
      this.userdetail = val.text;
      console.log("kk", val);
    },

    onAddressSub() {
      if (!this.checkInfo()) {
        return;
      }

      this.$emit("onAddressSub", {
        addressDetail: this.street + " " + this.userdetail,
        city: this.city, //市
        county: this.county, //区
        province: this.province, //省
        orderId: this.addressData.orderId,
        nickname: this.nickname.trim(),
        mobile: this.mobile.trim(),
      });
    },
    onConfirm(values) {
      this.areaCode = values[2].code;
      this.uservalue = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join("/");
      this.province = values[0].name;
      this.city = values[1].name;
      this.county = values[2].name;
      this.userdetail = "";
      this.showArea = false;
    },
    onFinish({ selectedOptions }) {
      console.log(selectedOptions);
      // this.show = false;
      this.uservalue = selectedOptions.map((option) => option.text).join("/");

      this.province = selectedOptions[0].text;

      this.city = selectedOptions[1].text;
      this.county = selectedOptions[2].text;
      this.street = selectedOptions[3].text;
      this.areaCode = selectedOptions[2].value;
      this.userdetail = "";
      this.showArea = false;
    },
    checkInfo() {
      if (this.nickname.trim()) {
        if (!validateRule.checkChinese(this.nickname.trim())) {
          Toast("姓名输入格式不对");
          return;
        }
        if (validateRule.chackRegExp(this.nickname.trim())) {
          Toast("姓名不能存在特殊字符");
          return false;
        }
      }
      if (!this.uservalue.trim()) {
        Toast("请选择地区");
        return false;
      }
      if (!this.userdetail.trim()) {
        Toast("请输入详细地址");
        return false;
      }

      return true;
    },
  },
};
</script>
<style>
.order_tab .van-tabs__wrap {
  left: 0;
}

.points-address {
  /* height: 100%; */
  /* overflow: scroll; */
  font-size: 14px;
}
.points-address .van-popover__wrapper .van-cell::before {
  /* border-bottom: 1px solid #ccc; */
  position: absolute;
  box-sizing: border-box;
  content: " ";
  pointer-events: none;
  right: 0.42667rem;
  bottom: 0;
  left: 0.42667rem;
  border-bottom: 0.02667rem solid #ebedf0;
  -webkit-transform: scaleY(0.5);
  -ms-transform: scaleY(0.5);
  transform: scaleY(0.5);
}
</style>
<style lang='scss' scoped>
.points-title {
  font-size: 18px;
  color: #494949;
  background-color: #fff;
  padding: 16px;
}
</style>
